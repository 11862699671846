
import './circleList.css'


const CircleList = () => {

    return(
        <div style={{width:"50%",textAlign:"-webkit-center"}}>
            <div style={{width:150,height:150,borderRadius:75,backgroundColor:'white',marginBottom:30,marginTop:20}}></div>
            <p className="circle-text">Lorem Ipsum</p>
            <p className="circle-text">Lorem Ipsum</p>
        </div>
    )
}

export default CircleList;